import { type FC, useEffect, useState } from 'react';
import { Button, Modal } from 'flowbite-react';
import { HTTPError } from 'ky';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { type GsErrors } from 'types/gs_errors';
import type { RegisteredKeyEvent } from 'types/registered_key_event';
import { patchRegisteredKeyEvent } from 'domains/api/patchRegisteredKeyEvent';
import FormInput from 'components/atoms/FormInput';

type EditKeyEventModalProps = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  keyEvent: RegisteredKeyEvent;
  analyticId: string;
};

type EditKeyEventData = {
  name: string;
};

const EditKeyEventModal: FC<EditKeyEventModalProps> = ({
  openModal,
  setOpenModal,
  keyEvent,
  analyticId,
}) => {
  const [loading, setLoading] = useState(false);
  const { setValue } = useForm();
  useEffect(() => {
    setValue('name', keyEvent?.name);
  }, [keyEvent?.name, setValue]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditKeyEventData>({
    defaultValues: {
      name: keyEvent?.name,
    },
  });

  const onSubmit: SubmitHandler<EditKeyEventData> = async (
    data: EditKeyEventData,
  ) => {
    try {
      setLoading(true);
      await patchRegisteredKeyEvent(analyticId, keyEvent.id.toString(), {
        name: data.name,
      });
      keyEvent.name = data.name;
      setLoading(false);
      setOpenModal(false);
      toast('レポート表示名を更新しました。', {
        progressStyle: { background: '#07bc0c' },
      });
    } catch (error: unknown) {
      if (error instanceof HTTPError) {
        const response = (await error.response.json()) as GsErrors;
        response.errors.forEach((errorMsg: string) => toast.error(errorMsg));
      }
      setLoading(false);
    }
  };

  const closeEditModal = () => {
    setOpenModal(false);
    setValue('name', '');
  };

  return (
    <Modal
      dismissible
      show={openModal}
      size="md"
      popup
      onClose={closeEditModal}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="space-y-6">
          <h3 className="text-xl font-medium text-gray-900 dark:text-white">
            キーイベントのレポート表示名編集
          </h3>
          <form className="max-w-xl pl-8" onSubmit={handleSubmit(onSubmit)}>
            {
              <FormInput
                label="レポート表示名"
                attribute="name"
                register={register('name', { required: true })}
                errors={errors}
                required={true}
              />
            }
            <div className="w-full">
              <Button type={'submit'} color={'gray'} isProcessing={loading}>
                保存
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditKeyEventModal;
